.pixel-art {
  image-rendering: pixelated;
  -ms-interpolation-mode: nearest-neighbor;
}

@keyframes bounceDesktop {
  0% {
    transform: scale(1) translateY(0);
    filter: hue-rotate(0deg) saturate(1) opacity(1);
  }
  50% {
    transform: scale(3) translateY(20px) rotate(360deg);
    filter: hue-rotate(180deg) saturate(1) opacity(1);
  }
  100% {
    transform: scale(1.2) translateY(130px) rotate(720deg);
    filter: hue-rotate(360deg) saturate(1) opacity(0);
  }
}

.kirby-bounce-desktop {
  animation: bounceDesktop 1.7s linear both;
}
@keyframes bounceMobile {
  0% {
    transform: scale(1) translateY(0);
    filter: hue-rotate(0deg) saturate(1) opacity(1);
  }
  50% {
    transform: scale(3) translateY(70px) rotate(360deg);
    filter: hue-rotate(180deg) saturate(1) opacity(1);
  }
  99% {
    transform: scale(1) translateY(170px) rotate(720deg);
    filter: hue-rotate(360deg) saturate(1) opacity(0);
  }
  100% {
    transform: scale(1) translateY(170px) rotate(720deg);
    filter: hue-rotate(360deg) saturate(1) opacity(0);
    z-index: -1;
  }
}

.kirby-bounce-mobile {
  animation: bounceMobile 1.7s linear both;
}
