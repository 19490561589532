@tailwind base;
@tailwind components;
@tailwind utilities;

/* Example of a custom font-face if needed */
@font-face {
  font-family: 'PixelOperator';
  src: url('./fonts/pixel_operator/PixelOperator.ttf');
  font-weight: normal;
  font-style: normal;
}

.pixel-font {
  font-family: 'PixelOperator', sans-serif;
}


/* Scrollbar customization can be done with a plugin or global CSS. */
/* Example override: */
::-webkit-scrollbar {
  width: 5px;
  background-color: black;
}
::-webkit-scrollbar-thumb {
  /* USE TAILWIND blue-300 */
  background-color: #90cdf4;

  border-radius: 6px;
}

/* Body defaults */
body {
  @apply m-0 overflow-x-hidden bg-black text-white;
  /* font-family: 'PixelOperator', sans-serif; */
}
/*  */
/* .App wrapper if you want a global container class */
.App {
  @apply h-screen w-full overflow-y-scroll;
}
